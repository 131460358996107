<template>
  <div class="bg-white" style="
      background: white;
    ">
    <div class="row">
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/good-conduct-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Kelakuan Baik</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/health-insurance-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Jamkes</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
       @click="$router.push('/iframe/cover-letter')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Pengantar KTP</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/unmarried-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Belum Kawin</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/poverty-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Kurang Mampu</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/jkbm-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan JKBM</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/death-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Kematian</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/birth-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Kelahiran</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/bussiness-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Usaha</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/resident-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Penduduk</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/transfer-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Pindah</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/bussiness-place-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Keterangan Tempat Usaha</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-12 my-2"
        @click="$router.push('/iframe/general-certificate')"
      >
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #ffffff; font-weight: 600"
                >Surat Umum</span
              >
              <b-button class="mr-2 icon-my-card-letter">
                <img
                  src="/media/anjungan/icon/email.png"
                  class="my-card-icon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-none">
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/resident-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Keterangan Penduduk</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/birth-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Kelahiran</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/death-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Kematian</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/bussiness-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Ijin Usaha</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a
          href="#"
          @click="$router.push('/iframe/bussiness-place-certificate')"
        >
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Domisili Usaha</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/transfer-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Pindah</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a
          href="#"
          @click="$router.push('/iframe/health-insurance-certificate')"
        >
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Keterangan Jamkes</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/cover-letter')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Pengantar KTP</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/poverty-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Kurang Mampu</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/good-conduct-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Kelakuan Baik</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/jkbm-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Keterangan JKBM</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/unmarried-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Keterangan Belum Kasdwin</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-2">
        <a href="#" @click="$router.push('/iframe/general-certificate')">
          <div class="card my-card-letter-iframe">
            <div class="card-body py-2">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="mt-2 my-card-letter-text"
                  style="
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                  "
                  >Surat Umum</span
                >
                <b-button class="mr-2 icon-my-card-letter-iframe">
                  <img src="/media/anjungan/icon/email.png" height="30" />
                </b-button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
body {
  background: #ffffff !important;
}
/* On screens that are 992px or less, set the background color to blue */
/* @media screen and (max-width: 992px) { */
.my-card-letter-iframe {
  /* background-image: linear-gradient(to top, #38c762, #088346); */
  /* border-color: transparent; */
  border-color: green;
  /* background-image: linear-gradient(to top, #c8c753, #4eaa79); */
  background: #086838;
  border-radius: 5px;
  cursor: pointer;
  min-height: 100px;
  height: auto;
}

.my-card-letter-iframe:hover {
  box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -webkit-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -moz-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
}

.icon-my-card-letter-iframe {
  /* width: 72px; */
  /* height: 69px; */
  border-radius: 46px;
  padding: 0;
  /* background: #ffffff; */
  background: none;
  /* border-color: #48aa7b; */
  border-color: transparent;
}

.my-card-letter-text {
  font-size: 22px !important;
}
/* } */

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .my-card-letter-iframe {
    /* background-image: linear-gradient(to top, #38c762, #088346); */
    /* border-color: transparent; */
    border-color: green;
    /* background-image: linear-gradient(to top, #c8c753, #4eaa79); */
    background: #086838;
    border-radius: 5px;
    cursor: pointer;
    /* height: 54px; */
    height: auto;
  }

  .my-card-letter-iframe:hover {
    box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
    -webkit-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
    -moz-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  }

  .icon-my-card-letter-iframe {
    /* width: 72px; */
    /* height: 69px; */
    border-radius: 46px;
    padding: 0;
    /* background: #ffffff; */
    background: none;
    /* border-color: #48aa7b; */
    border-color: transparent;
  }

  .my-card-letter-text {
    font-size: 18px !important;
  }
}
</style>

<style scoped>
.nowrap {
  white-space: nowrap;
}

.btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 35px;
  padding: 0;
}

.btn-circle-sm {
  width: 18px;
  height: 18px;
  border-radius: 30px;
  padding: 0;
}

.btn-circle i {
  font-size: 22px;
}

.my-card-letter {
  /* background-image: linear-gradient(to top, #38c762, #088346); */
  /* background: #ffffff; */
  border-color: green;
  background-image: linear-gradient(to top, #c8c753, #4eaa79);
  color: white !important;
  /* border-radius: 20px; */
  cursor: pointer;
}

.my-card-letter:hover {
  box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -webkit-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -moz-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
}

.icon-my-card-letter {
  width: 72px;
  height: 69px;
  border-radius: 46px;
  padding: 0;
  /* background: #ffffff; */
  background: none;
  /* border-color: #48aa7b; */
  border-color: transparent;
}

.my-card-icon {
  height: 40px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .my-card-letter-iframe {
    /* background-image: linear-gradient(to top, #38c762, #088346); */
    /* border-color: transparent; */
    border-color: green;
    background-image: linear-gradient(to top, #c8c753, #4eaa79);
    /* background: #ffffff; */
    border-radius: 5px;
    cursor: pointer;
    /* height: 54px; */
    height: auto;
  }

  .my-card-letter-iframe:hover {
    box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
    -webkit-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
    -moz-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  }

  .icon-my-card-letter-iframe {
    /* width: 72px; */
    /* height: 69px; */
    border-radius: 46px;
    padding: 0;
    /* background: #ffffff; */
    background: none;
    /* border-color: #48aa7b; */
    border-color: transparent;
  }

  .my-card-letter-text {
    font-size: 18px !important;
  }

  .my-card-icon {
    height: 30px;
  }

  .my-card-letter-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>